<template>
  <div class="content-wrapper pad">
    <section class="stories-list">
      <load-more
        :endpoint="storiesEndpoint"
        :item-class-func="storyItemAdditionalClasses"
        first-item-class="is-half-tablet is-one-third-desktop"
        width="column is-flex-tablet"
      >
        <template #firstItem>
          <story-add
            :brand-id="newsroom.brand.id"
            class="is-shadowless"
          />
        </template>

        <template #noItems>
          <div key="noItems" class="column is-half-tablet is-one-third-desktop">
            <a :href="$getUrl(`/stories/create?brand=${newsroom.brand.id}`, 'hypefactors')" target="hf_story_create">
              <fake-data-ribbon is-visible size="small">
                <img :src="$asset('/images/sections/stories/dummy_story_card.jpg')" alt="">
              </fake-data-ribbon>
            </a>
          </div>
        </template>

        <template #item="props">
          <story-card
            :story="props.item"
            :newsroom-prop="newsroom"
            :show-pinned-posts="true"
            :show-less-info="true"
            :is-editable="true"
          />
        </template>
      </load-more>
    </section>
  </div>
</template>

<script>
import StoryAdd from '@/components/newsroomEdit/StoryAdd.vue'
import StoryCard from '@/components/storySearch/StoryCard.vue'
import NewsroomChild from '@/pages/newsroom/NewsroomChild'

export default {
  name: 'NewsroomEditStories',

  components: {
    StoryCard,
    StoryAdd
  },

  extends: NewsroomChild,

  data () {
    return {
      pageName: 'Stories'
    }
  },

  computed: {
    storiesEndpoint () { return `stories?newsrooms[]=${this.newsroom.id}&sort[]=newest` }
  },

  methods: {
    storyItemAdditionalClasses (story, index) {
      return story.is_pinned
        ? (index === 0 ? 'is-expanded' : 'is-full-tablet is-full-desktop')
        : 'is-half-tablet is-one-third-desktop'
    }
  }
}
</script>
