<template>
  <div class="StoryAdd card">
    <a
      :href="$getUrl(`/stories/create?brand=${brandId}`, 'hypefactors')"
      class="card-content"
      target="hf_story_create"
    >
      <div class="has-text-centered">
        <div class="title is-4">
          {{ $t('pages.newsroom_edit.create_new_story') }}
        </div>

        <i class="hf hf-plus hf-2x" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'StoryAdd',
  props: {
    brandId: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.StoryAdd {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 150px;

  @include tablet() {
    min-height: 350px;
  }

  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
